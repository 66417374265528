
import {defineComponent, onMounted, ref, toRef} from 'vue';
import {nanoid} from 'nanoid'
import {useRoute} from "vue-router";
import {BoostpackService} from "@/services/api/boostpack/boostpack.service";
import DefaultButton from "@/components/Button/DefaultButton.vue";
import {BoostpackFactory} from "@/services/boostpack/boostpack_factory.type";
import {DateHelper} from "@/services/boostpack/helper/date_helper";
import {MAIL_CHANNEL, TELEGRAM_CHANNEL} from "@/services/boostpack/channel/abstract_channel.type";
import {BoostpackItemList} from "@/services/boostpack/boostpack_item_list.type";
import {Boostpack} from "@/services/boostpack/boostpack.type";
import useCustomStore from "@/composable/custom/useCustomStore";
import useBoostpackItemRepositories from "@/composable/boostpack/useBoostpackItemRepositories";
import BoostpackSubscribeCheckout from "@/components/Boostpack/Subscribe/BoostpackSubscribeCheckout.vue";

export default defineComponent({
	name: 'SubscribeDialog',
	components: {
		BoostpackSubscribeCheckout,
		DefaultButton
	},
	props: {
		propBoostpackId: {
			type: Number,
			default: null,
		},
		propBoostpackReference: {
			type: String,
			default: null,
		},
		enableScale: {
			type: Boolean,
			default: true,
		},
		id: {type: String, default: nanoid()},
		title: {type: String},
		description: {type: String},
		visible: {type: Boolean, default: false},
		showButton: {
			type: Boolean,
			default: true,
		},
	},
	emits: ['confirm', 'cancelConfirm', 'showSubscribeAd'],
	setup(props, {emit}) {
		const dialogTitle = toRef(props, 'title');
		const dialogDescription = toRef(props, 'description');
		const visible = toRef(props, 'visible');
		const dialogVisible = ref(visible.value);
		const showAd = ref(visible.value);
		const showStatus = ref(visible.value);
		const {
			getBoostpackById,
			getBoostpackByReference,
			addBoostpack,
			removeBoostpack
		} = useBoostpackItemRepositories();
		const {getPageReloaded, setPageReloaded} = useCustomStore();
		const pageReloaded = ref(getPageReloaded);
		const boostpack = ref<Boostpack>();
		const boostpackId = ref();
		const boostpackReference = ref();
		const boostpackFound = ref(false);
		const boostpackTitle = ref("");
		const boostpackItems = ref<BoostpackItemList>();
		const boostpackDescription = ref("");
		const categoryTitle = ref("");
		const boostpackDate = ref();
		const boostpackPeriod = ref("");
		const boostpackCreator = ref("");
		const boostpackCreatorImage = ref();
		const boostpackTitleImage = ref();
		const boostpackLoading = ref(true);
		const selectedChannel = ref(MAIL_CHANNEL);
		const channelData = ref({
			nickname: "",
			email: "",
			date: "",
			time: "",
			duration: "",
		});
		const showMail = ref(visible.value);
		const showTelegram = ref(visible.value);
		const errorMessage = ref("");
		const errorTitle = ref("");

		function showDialog() {
			dialogVisible.value = true;
		}

		function showSubscribeAd() {
			showAd.value = !showAd.value;
		}

		function hideDialog() {
			dialogVisible.value = false;
		}

		const cancelDialog = () => {
			//TODO show cancel confirmation if necessary step 2, 3
			dialogVisible.value = false;
			emit('cancelConfirm');
		};

		const confirm = () => {
			dialogVisible.value = false;
			emit('confirm');
		};

		//TODO watch getBoostpackById changed!
		function getBoostpackDaysByDate(): number {
			if (boostpack.value && boostpack.value.boostpackItems) {
				return boostpack.value.boostpackItems.length;
			}
			return 0;
		}

		function formatBoostpackDate(): string {
			let result = "";
			if (boostpack.value && boostpack.value.createdAt) {
				const date = new Date(boostpack.value.createdAt);
				const dateHelper = new DateHelper();
				result = dateHelper.formatDate(date);
			}
			return result;
		}

		function initBoostpack() {
			if (boostpack.value && boostpack.value.id > 0) {
				boostpackTitle.value = boostpack.value.title;
				boostpackDescription.value = boostpack.value.description;
				boostpackItems.value = boostpack.value.boostpackItems;
				categoryTitle.value = boostpack.value.category.categoryName;
				boostpackCreator.value = boostpack.value.user.name;
				if (boostpack.value.user.profilePhotoUrl) {
					boostpackCreatorImage.value = boostpack.value.user.profilePhotoUrl;
				}
				boostpackDate.value = formatBoostpackDate();
				if (boostpack.value.period) {
					boostpackPeriod.value = getBoostpackDaysByDate().toString();
				}
				boostpackFound.value = true;
				const boostpackFactory = new BoostpackFactory();
				boostpackTitleImage.value = boostpackFactory.createRandomTitleImageByBoostpackItemList(boostpackItems.value);
			}
		}

		/**
		 *
		 * @param boostpackId
		 */
		async function loadDetailBoostpackById(boostpackId?: number) {
			if (boostpackId) {
				const boostpackDetail = await getBoostpackById(boostpackId);
				if (!boostpackDetail) {
					const bpDetailTmp = await BoostpackService.getBoostpack(boostpackId);
					if (bpDetailTmp) {
						addBoostpack(bpDetailTmp);
						boostpack.value = getBoostpackById(boostpackId);
					}
				} else {
					boostpack.value = boostpackDetail;
				}
				initBoostpack();
			}
			boostpackLoading.value = false;
		}

		onMounted(() => {
			if (props.propBoostpackId) {
				boostpackId.value = props.propBoostpackId;
			} else if (props.propBoostpackReference && props.propBoostpackReference.length > 0) {
				boostpackReference.value = props.propBoostpackReference;
			} else {
				const referenceFromRoute = useRoute().params.id;
				if (!isNaN(Number(referenceFromRoute))) {
					boostpackId.value = Number(referenceFromRoute);
				} else {
					boostpackReference.value = referenceFromRoute;
				}
			}
			if (boostpackId.value) {
				boostpack.value = getBoostpackById(boostpackId.value);
			} else if (boostpackReference.value) {
				boostpack.value = getBoostpackByReference(boostpackReference.value);
			}

			if (pageReloaded.value && boostpack.value && boostpack.value.id) {
				removeBoostpack(boostpack.value.id);
				setPageReloaded(false);
				if (boostpackId.value) {
					loadDetailBoostpackById(boostpackId.value);
				}
			} else {
				if (boostpack.value) {
					initBoostpack();
				} else {
					if (boostpackId.value) {
						loadDetailBoostpackById(boostpackId.value);
					}
				}
			}
		})

		return {
			boostpack,
			boostpackId,
			boostpackFound,
			boostpackTitle,
			boostpackDescription,
			categoryTitle,
			boostpackCreator,
			boostpackCreatorImage,
			boostpackDate,
			boostpackPeriod,
			boostpackItems,
			boostpackTitleImage,
			boostpackLoading,
			dialogTitle,
			dialogDescription,
			dialogVisible,
			showAd,
			showStatus,
			showSubscribeAd,
			showDialog,
			hideDialog,
			confirm,
			cancelDialog,
			channelData,
			showMail,
			showTelegram,
			errorTitle,
			errorMessage,
		}
	},
	methods: {}
})
