
import {defineComponent, ref, onMounted} from 'vue';
import {useRoute} from "vue-router";
import useTranslation from "@/composable/translation/useTranslation";
import {BoostpackService} from "@/services/api/boostpack/boostpack.service";
import DefaultButton from "@/components/Button/DefaultButton.vue";
import SuccessAlert from "@/components/Alert/Base/SuccessAlert.vue";

export default defineComponent({
	name: "BoostpackUnsubscribe",
	components: {DefaultButton},
	props: {
		uuid: {type: String, default: null},
	},
	emits: ['closeCheckout'],
	setup(props, {emit}) {
		const {t} = useTranslation();
		const boostpackUnsubscribeHash: any = ref("");
		const boostpackTitle = ref("");
		const isValidHash = ref(false);
		const unsubscribeSuccessful = ref(false);

		async function unsubscribe() {
			const response = await BoostpackService.unsubscribe({hash: boostpackUnsubscribeHash.value});
			unsubscribeSuccessful.value = !!response.data.success;
		}

		async function checkHash() {
			const response = await BoostpackService.checkUnsubscribeHash({hash: boostpackUnsubscribeHash.value});
			isValidHash.value = !!response.data.success;
		}

		onMounted(() => {
			boostpackUnsubscribeHash.value = useRoute().params.hash;
			checkHash();
		});

		return {
			unsubscribe,
			isValidHash,
			unsubscribeSuccessful
		}
	}
})
