

export default {
	name: "NotFoundComponent",
	components: {},
	props: {},
	setup() {
		return {};
	}
}
