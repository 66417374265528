
import {CheckIcon} from '@heroicons/vue/outline'

const hobbyFeatures = ['Pariatur quod similique', 'Sapiente libero doloribus', 'Vel ipsa esse repudiandae']
const scaleFeatures = ['Pariatur quod similique', 'Sapiente libero doloribus', 'Vel ipsa esse repudiandae']
const growthFeatures = [
  'Quia rem est sed impedit magnam',
  'Dolorem vero ratione voluptates',
  'Qui sed ab doloribus voluptatem dolore',
  'Laborum commodi molestiae id et fugiat',
  'Nam ut ipsa nesciunt culpa modi dolor',
]

export default {
  name: "PricingList",
  components: {
    CheckIcon,
  },
  setup() {
    return {
      hobbyFeatures,
      scaleFeatures,
      growthFeatures,
    }
  },
}
