export class DateHelper
{

	/**
	 *
	 * @param date
	 * @param days
	 */
	addDaysToDate(date: Date, days: number): Date
	{
		const newDate = new Date();
		newDate.setDate(date.getDate() + days);
		return newDate;
	}

	/**
	 *
	 * @param dateFrom
	 * @param dateTo
	 */
	daysUntilDate(dateFrom: Date, dateTo: Date): number
	{
		const DifferenceInTime = dateTo.getTime() - dateFrom.getTime();
		return DifferenceInTime / (1000 * 3600 * 24);
	}

	/**
	 *
	 * @param date
	 * @param locales
	 * @param options
	 *  example: { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
	 * "Donnerstag, 20. Dezember 2012"
	 */

	formatDate(date: Date, locales = "de-DE", options = {year: '2-digit', month: 'numeric', day: 'numeric'}): string {
		return date.toLocaleDateString(locales, options);
	}

}