
import {defineComponent, onMounted, ref} from 'vue';
import {useRoute} from "vue-router";
import DefaultCardLayout from "@/components/Card/DefaultCardLayout.vue";
import DefaultImageLayout from "@/components/Card/DefaultImageLayout.vue";
import BtnClose from "@/components/Nav/BtnClose.vue";
import DefaultIcon from "@/components/Icon/DefaultIcon.vue";
import useBoostpackItemRepositories from "@/composable/boostpack/useBoostpackItemRepositories";
import {BoostpackService} from "@/services/api/boostpack/boostpack.service";
import {Boostpack} from "@/services/boostpack/boostpack.type";
import {BoostpackItemList} from "@/services/boostpack/boostpack_item_list.type";
import DefaultButton from "@/components/Button/DefaultButton.vue";
import {BoostpackFactory} from "@/services/boostpack/boostpack_factory.type";
import {DateHelper} from "@/services/boostpack/helper/date_helper";
import useCustomStore from "@/composable/custom/useCustomStore";
import DefaultSpinner from "@/components/Loading/DefaultSpinner.vue";
import useBoostpackItemHelper from "@/composable/boostpack/useBoostpackItemHelper";
import SubscribeDialog from "@/components/Modal/SubscribeDialog.vue";
import DefaultAd from "@/components/Ads/DefaultAd.vue";
import router from "@/router";

export default defineComponent({
	name: 'DefaultBoostpackDetail',
	components: {
		DefaultAd,
		SubscribeDialog,
		DefaultSpinner,
		DefaultButton,
		DefaultIcon,
		BtnClose, DefaultImageLayout, DefaultCardLayout
	},
	props: {
		propBoostpackId: {
			type: Number,
			default: null,
		},
		propBoostpackReference: {
			type: String,
			default: null,
		},
		cardWidth: {
			type: String,
			default: "w-full",
		},
		enableSharing: {
			type: Boolean,
			default: true,
		},
		enableScale: {
			type: Boolean,
			default: true,
		},
	},
	setup(props) {
		const {
			getBoostpackById,
			getBoostpackByReference,
			addBoostpack,
			removeBoostpack
		} = useBoostpackItemRepositories();
		const {getAltImageTextByBoostpackItem} = useBoostpackItemHelper();
		const {getPageReloaded, isFullCookieConsentAllowed, setPageReloaded} = useCustomStore();
		const pageReloaded = ref(getPageReloaded);
		const boostpack = ref<Boostpack>();
		const boostpackId = ref();
		const boostpackReference = ref();
		const boostpackFound = ref(false);
		const boostpackTitle = ref("");
		const boostpackItems = ref<BoostpackItemList>();
		const boostpackDescription = ref("");
		const categoryTitle = ref("");
		const boostpackDate = ref();
		const boostpackPeriod = ref("");
		const boostpackCreator = ref("");
		const boostpackCreatorImage = ref();
		const boostpackTitleImage = ref();
		const boostpackLoading = ref(true);
		const readMoreActivated = ref(false);
		let lastAdIndex = 0;

		function goBackToFeed() {
			if (boostpack.value) {
				router.push('/feed/boostpacks?boostpack_reference=boostpackCard_' + boostpack.value.reference);
			} else {
				router.push('/feed');
			}
		}

		//TODO watch getBoostpackById changed!
		function getBoostpackDaysByDate(): number {
			if (boostpack.value && boostpack.value.boostpackItems) {
				return boostpack.value.boostpackItems.length;
			}
			return 0;
		}

		function formatBoostpackDate(): string {
			let result = "";
			if (boostpack.value && boostpack.value.createdAt) {
				const date = new Date(boostpack.value.createdAt);
				const dateHelper = new DateHelper();
				result = dateHelper.formatDate(date);
			}
			return result;
		}

		//TODO check detail images count with api and update or delete images

		function sanitizeDescription(description: string): string
		{
			if (!isFullCookieConsentAllowed()) {
				const descriptionElement = document.createElement("div");
				descriptionElement.innerHTML = description;
				[...descriptionElement.querySelectorAll('iframe')].forEach(ifr => ifr.remove())
				return descriptionElement.innerHTML;
			}
			return description;
		}

		function initBoostpack() {
			if (boostpack.value && boostpack.value.id > 0) {
				boostpackTitle.value = boostpack.value.title;
				boostpackDescription.value = sanitizeDescription(String(boostpack.value.description));
				boostpackItems.value = boostpack.value.boostpackItems;
				categoryTitle.value = boostpack.value.category.categoryName;
				boostpackCreator.value = boostpack.value.user.name;
				if (boostpack.value.user.profilePhotoUrl) {
					boostpackCreatorImage.value = boostpack.value.user.profilePhotoUrl;
				}
				boostpackDate.value = formatBoostpackDate();
				if (boostpack.value.period) {
					boostpackPeriod.value = getBoostpackDaysByDate().toString();
				}
				boostpackFound.value = true;
				const boostpackFactory = new BoostpackFactory();
				boostpackTitleImage.value = boostpackFactory.createRandomTitleImageByBoostpackItemList(boostpackItems.value);
			}
		}

		/**
		 *
		 * @param boostpackId
		 */
		async function loadDetailBoostpackById(boostpackId?: number) {
			if (boostpackId) {
				const boostpackDetail = await getBoostpackById(boostpackId);
				if (!boostpackDetail) {
					BoostpackService.getBoostpack(boostpackId).then((bpDetailTmp) => {
						if (bpDetailTmp) {
							addBoostpack(bpDetailTmp);
							boostpack.value = getBoostpackById(boostpackId);
							initBoostpack();
							boostpackLoading.value = false;
						}
					});
				} else {
					boostpack.value = boostpackDetail;
					initBoostpack();
				}
			} else {
				boostpackLoading.value = false;
			}
		}

		/**
		 *
		 * @param boostpackReference
		 */
		async function loadDetailBoostpackByReference(boostpackReference?: string) {
			if (boostpackReference) {
				const boostpackDetail = await getBoostpackByReference(boostpackReference);
				if (!boostpackDetail) {
					BoostpackService.getBoostpackByReference(boostpackReference).then((bpDetailTmp) => {
						if (bpDetailTmp) {
							addBoostpack(bpDetailTmp);
							boostpack.value = getBoostpackByReference(boostpackReference);
							initBoostpack();
							boostpackLoading.value = false;
						}
					});
				} else {
					boostpack.value = boostpackDetail;
					initBoostpack();
				}
			} else {
				boostpackLoading.value = false;
			}
		}

		onMounted(() => {
			window.scrollTo(0, 0);
			if (props.propBoostpackId) {
				boostpackId.value = props.propBoostpackId;
			} else if (props.propBoostpackReference && props.propBoostpackReference.length > 0) {
				boostpackReference.value = props.propBoostpackReference;
			} else {
				const referenceFromRoute = useRoute().params.id;
				if (!isNaN(Number(referenceFromRoute))) {
					boostpackId.value = Number(referenceFromRoute);
				} else {
					boostpackReference.value = referenceFromRoute;
				}
			}
			if (boostpackId.value) {
				boostpack.value = getBoostpackById(boostpackId.value);
			} else if (boostpackReference.value) {
				boostpack.value = getBoostpackByReference(boostpackReference.value);
			}

			if (pageReloaded.value && boostpack.value && boostpack.value.id) {
				removeBoostpack(boostpack.value.id);
				setPageReloaded(false);
				if (boostpackId.value) {
					loadDetailBoostpackById(boostpackId.value);
				} else if (boostpackReference.value) {
					loadDetailBoostpackByReference(boostpackReference.value);
				}
			} else {
				if (boostpack.value) {
					initBoostpack();
				} else {
					if (boostpackId.value) {
						loadDetailBoostpackById(boostpackId.value);
					} else if (boostpackReference.value) {
						loadDetailBoostpackByReference(boostpackReference.value);

					}
				}
			}
			// boostpackLoading.value = false;
		})

		function buyaCoffe() {
			window.location.href = "https://ko-fi.com/theconcept_technologies";
		}

		/**
		 *
		 */
		function isBoostpackLoading(): boolean {
			return boostpackLoading.value === true;
		}

		/**
		 *
		 */
		function hasBoostpack(): boolean {
			return boostpackFound.value === true;
		}

		window.addEventListener('beforeunload', function () {
			setPageReloaded(true);
		})

		function toggleReadMore() {
			readMoreActivated.value = !readMoreActivated.value;
		}

		function getRandomInt(max) {
			return Math.floor(Math.random() * max);
		}

		function isAdEnabled(index): boolean {
			if (boostpackItems.value && index) {
				const calcIndex = index + 1;
				const boostpackLength = boostpackItems.value.length;
				const minAdDistance = 2;
				const maxAdDistance = 8;
				const adDistance = Math.abs(calcIndex - lastAdIndex);
				const randomIndex = getRandomInt(calcIndex);
				const randomBoostpackLength = getRandomInt(boostpackLength);
				if ((randomIndex > randomBoostpackLength && adDistance > minAdDistance) || (adDistance > maxAdDistance)) {
					lastAdIndex = calcIndex;
					return true;
				} else {
					return false;
				}
			}
			return false;
		}

		return {
			boostpack,
			boostpackId,
			boostpackFound,
			boostpackTitle,
			boostpackDescription,
			categoryTitle,
			boostpackCreator,
			boostpackCreatorImage,
			boostpackDate,
			boostpackPeriod,
			boostpackItems,
			boostpackTitleImage,
			boostpackLoading,
			hasBoostpack,
			buyaCoffe,
			isBoostpackLoading,
			getAltImageTextByBoostpackItem,
			readMoreActivated,
			toggleReadMore,
			isAdEnabled,
			goBackToFeed,
		};
	},
});
